@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .bg-main {
        @apply bg-[#051214]
    }
    .swiper-disabled {
        @apply !opacity-0 pointer-events-none
    }
    body,html {
        font-family: inter;
    }
    .bg-instagram {
        background: #f09433;
        background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
    }
    blockquote {
        @apply p-4 my-4 border-s-4 border-gray-500 bg-gray-800
    }
    .tour ul {
        @apply space-y-0.5 list-disc list-inside py-2
    }
    .article figure.attachment{
        @apply my-6
    }
    .article img{
        @apply w-full h-auto object-contain
    }
    .article h4 {
        @apply font-semibold my-1
    }

}